import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import hljs from 'highlight.js';
import { BackendApiService } from '../../service/backend-api.service';
import { Utils } from '../../util/utils';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrl: './blog-post.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BlogPostComponent implements OnInit {
  @ViewChild('content') content!: ElementRef<HTMLDivElement>;
  post: any;

  constructor(
    private backendApiService: BackendApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug');
    if (slug) {
      this.getPostBySlug(slug);
      this.loadBlogContent(slug);
    }
  }

  getPostBySlug(slug: string): void {
    this.backendApiService.callGetPostBySlugAPI(slug).subscribe({
      next: (successResponse) => {
        this.post = successResponse.post;
      },
      error: (errorResponse) => {
        console.error(errorResponse);
      },
    });
  }

  loadBlogContent(slug: string): void {
    this.backendApiService.callGetBlogContentAPI(slug).subscribe({
      next: (successResponse) => {
        this.loadHtmlContent(successResponse);
      },
      error: (errorResponse) => {
        console.error(errorResponse);
      },
    });
  }

  loadHtmlContent(htmlContent: string): void {
    const div = this.renderer.createElement('div');
    div.innerHTML = htmlContent;
    this.renderer.appendChild(this.content.nativeElement, div);
    this.highlightCodeBlocks();
  }

  highlightCodeBlocks(): void {
    this.content.nativeElement.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightElement(block as HTMLElement);
    });
  }

  getPublishedDate(publishedAt: string) {
    return Utils.parseDate(publishedAt, this.datePipe);
  }

  onCategorySelect(categoryId: number) {
    this.router.navigate(['/'], {
      queryParams: { category: categoryId },
    });
  }
}
