<!-- Navbar Starts -->
<nav>
  <div class="branding" [routerLink]="['/']">
    <i class="fa-regular fa-folder-open"></i>
    <p>Dev Rezaur</p>
  </div>

  <!-- <a href="https://devacademybd.devrezaur.com" target="_blank">Courses</a> -->
</nav>
<!-- Navbar Ends -->
