<!-- Blog Post Starts -->
<div class="blog-post">
  <div class="stats" *ngIf="post">
    <p><b>Total Read:</b> {{ post.totalRead }}</p>
    <p><b>Published At:</b> {{ getPublishedDate(post.publishedAt) }}</p>
  </div>

  <div #content class="content"></div>

  <div class="category" *ngIf="post">
    <p
      *ngFor="let category of post.categories"
      (click)="onCategorySelect(category.id)"
    >
      {{ category.name }}
    </p>
  </div>
</div>
<!-- Blog Post Ends -->
