<!-- Categories Starts -->
<div class="categories" *ngIf="categories.length > 0">
  <h3>Categories</h3>

  <div class="category">
    <p
      [ngClass]="{ active: isSelectedCategory(category.id) }"
      *ngFor="let category of categories"
      (click)="onCategorySelect(category.id)"
    >
      {{ category.name }} ({{ category.count }})
    </p>
  </div>
</div>
<!-- Categories Ends -->
