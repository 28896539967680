<!-- Blog List Starts -->
<div class="blog-list" *ngIf="posts != null">
  <div class="card-wrapper" *ngIf="posts.length > 0">
    <div
      class="card"
      *ngFor="let post of posts"
      (click)="readBlogPost(post.slug)"
    >
      <div class="top-div">
        <img
          [src]="getImage(post.slug, post.imageUrl)"
          width="300"
          height="150"
          alt="Banner Image"
          loading="lazy"
        />
        <div class="stats">
          <i class="fa-regular fa-eye"></i>
          <p>
            {{ post.totalRead }}
            &#x2022;
            {{ getPublishedDate(post.publishedAt) }}
          </p>
        </div>
      </div>
      <div class="mid-div">
        <p *ngFor="let category of post.categories">{{ category.name }}</p>
      </div>
      <div class="bottom-div">
        <h3>{{ post.title }}</h3>
      </div>
    </div>
  </div>

  <app-no-content *ngIf="posts.length === 0"></app-no-content>

  <div class="button-wrapper">
    <button
      class="button"
      [disabled]="!hasPreviousPage()"
      (click)="loadPreviousPage()"
    >
      Previous
    </button>
    <button class="button" [disabled]="!hasNextPage()" (click)="loadNextPage()">
      Next
    </button>
  </div>
</div>
<!-- Blog List Ends -->
