<!-- Author Details Starts -->
<div class="author-details">
  <div class="grid-wrapper">
    <div class="left-div">
      <img
        src="../../../assets/app/image/rezaur-rahman-photo.webp"
        width="200"
        height="200"
        alt="Author Image"
        loading="lazy"
      />
    </div>

    <div class="right-div">
      <h3>Rezaur Rahman</h3>
      <p class="title">
        Software Engineer, BJIT &#x2022; Founder, Dev Academy BD
      </p>
      <p class="description">
        My name is Rezaur Rahman and this is my personal tech-blog. Here I write
        about different technologies like Java, Spring Boot, Angular, AWS and
        other cloud native technologies. If you are interested in these
        technologies and other system design concepts, then feel free to explore
        my blog.
      </p>

      <div class="social-platforms">
        <p>Follow me on:</p>
        <a href="https://www.linkedin.com/in/rezaurofficial" target="_blank">
          <i class="fa-brands fa-linkedin"></i>
          <span class="sr-only">LinkedIn Profile</span>
        </a>
        <a href="https://github.com/DevRezaur" target="_blank">
          <i class="fa-brands fa-github-square"></i>
          <span class="sr-only">Github Profile</span>
        </a>
        <a href="https://t.me/RezaurRahman" target="_blank">
          <i class="fa-brands fa-telegram-plane"></i>
          <span class="sr-only">Telegram Profile</span>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Author Details Ends -->
