import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackendApiService } from '../../service/backend-api.service';
import { Utils } from '../../util/utils';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrl: './blog-list.component.scss',
})
export class BlogListComponent implements OnInit, OnDestroy {
  private queryParametersSubscription = new Subscription();
  categoryId: number = 0;
  currentPageNo: number = 0;
  totalPosts: number = 0;
  postPerPage: number = 0;
  posts: any[] | null = null;

  constructor(
    private backendApiService: BackendApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.queryParametersSubscription =
      this.activatedRoute.queryParamMap.subscribe((params: any) => {
        this.categoryId = params.has('category') ? +params.get('category') : 1;
        this.currentPageNo = params.has('page') ? +params.get('page') : 1;
        this.getPostsByCategoryId();
      });
  }

  ngOnDestroy() {
    this.queryParametersSubscription.unsubscribe();
  }

  getPostsByCategoryId(): void {
    this.backendApiService
      .callGetPostsByCategoryId(this.categoryId, this.currentPageNo)
      .subscribe({
        next: (successResponse) => {
          this.totalPosts = successResponse.meta.totalPosts;
          this.postPerPage = successResponse.meta.postPerPage;
          this.posts = successResponse.postList;
        },
        error: (errorResponse) => {
          console.error(errorResponse);
        },
      });
  }

  getPublishedDate(publishedAt: string) {
    return Utils.parseDate(publishedAt, this.datePipe);
  }

  getImage(slug: string, imageUrl: string) {
    return `../../../assets/static-page/${slug}/image/${imageUrl}`;
  }

  readBlogPost(slug: string): void {
    this.router.navigate([`/blog/${slug}`]);
  }

  hasPreviousPage(): boolean {
    return this.currentPageNo > 1;
  }

  hasNextPage(): boolean {
    return this.currentPageNo < Math.ceil(this.totalPosts / this.postPerPage);
  }

  loadPreviousPage(): void {
    this.router.navigate([], {
      queryParams: { page: --this.currentPageNo },
      queryParamsHandling: 'merge',
    });
  }

  loadNextPage(): void {
    this.router.navigate([], {
      queryParams: { page: ++this.currentPageNo },
      queryParamsHandling: 'merge',
    });
  }
}
