import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendApiService } from '../../service/backend-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss',
})
export class CategoriesComponent implements OnInit, OnDestroy {
  private queryParametersSubscription = new Subscription();
  selectedCategoryId: number = 0;
  categories: any[] = [];

  constructor(
    private backendApiService: BackendApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      this.selectedCategoryId = params.has('category')
        ? +params.get('category')
        : 1;
    });
  }

  ngOnDestroy() {
    this.queryParametersSubscription.unsubscribe();
  }

  getCategories(): void {
    this.backendApiService.callGetCategoriesAPI().subscribe({
      next: (successResponse) => {
        this.categories = successResponse.categoryList;
      },
      error: (errorResponse) => {
        console.error(errorResponse);
      },
    });
  }

  isSelectedCategory(categoryId: number): boolean {
    return this.selectedCategoryId === categoryId;
  }

  onCategorySelect(categoryId: number) {
    this.router.navigate([], {
      queryParams: { category: categoryId, page: null },
      queryParamsHandling: 'merge',
    });
  }
}
