import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { AuthorDetailsComponent } from './component/author-details/author-details.component';
import { CategoriesComponent } from './component/categories/categories.component';
import { BlogListComponent } from './component/blog-list/blog-list.component';
import { BlogPostComponent } from './component/blog-post/blog-post.component';
import { HomePageComponent } from './page/home-page/home-page.component';
import { BlogPageComponent } from './page/blog-page/blog-page.component';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NoContentComponent } from './component/no-content/no-content.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomePageComponent,
    BlogListComponent,
    CategoriesComponent,
    AuthorDetailsComponent,
    BlogPageComponent,
    BlogPostComponent,
    NoContentComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
