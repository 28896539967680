import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackendApiService {
  private baseUrl = 'https://backend.devrezaur.com/api';

  constructor(private httpClient: HttpClient) {}

  callGetCategoriesAPI(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/category');
  }

  callGetPostsByCategoryId(
    categoryId: number,
    pageNo: number
  ): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/post/category/${categoryId}?pageNo=${pageNo}`
    );
  }

  callGetPostBySlugAPI(slug: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/post/slug/${slug}`);
  }

  callGetBlogContentAPI(slug: string): Observable<any> {
    const postUrl = `assets/static-page/${slug}/post.html`;
    return this.httpClient.get(postUrl, {
      responseType: 'text',
      headers: { 'Content-Type': 'text/html' },
    });
  }
}
